import React, { useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import { cilLockLocked, cilUser } from '@coreui/icons-pro'
import { useDispatch } from 'react-redux'
import { UserLogin } from '../../../app/models/userLogin'
import { setCredentials } from '../../../features/auth/authSlice'
import { useLoginMutation } from '../../../features/auth/authApiSlice'
import { CLoadingButton, CToaster } from '@coreui/react-pro'
import { sendToast } from '../../../app/toasts'

const Login = () => {
  const [login, { isLoading }] = useLoginMutation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [state, setState] = useState<UserLogin>({
    email: '',
    password: ''
  });

  const [toast, addToast] = useState<any>(0)

  const update = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const submitForm = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    try {
      await login({
        email: state.email,
        password: state.password,
      }).unwrap()
        .then((payload: any) => {
          setState({ email: '', password: '' })
          dispatch(setCredentials({ ...payload }))
          navigate('/')
        })
        .catch((error: any) => {
          setState({ email: '', password: '' })
          for (var i in error.data.messages) {
            addToast(sendToast(error.data.messages[i]))
          }
        })
    } catch (err) {
    }
  };

  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={6}>
            <CCardGroup>
              <CCard className="p-4 needs-validation">
                <CCardBody>
                  <CForm onSubmit={submitForm}
                  >
                    <h1>Login</h1>
                    <p className="text-medium-emphasis">Sign In to your account</p>
                    <CInputGroup className="mb-3 has-validation">
                      <CInputGroupText>
                        <CIcon icon={cilUser} />
                      </CInputGroupText>
                      <CFormInput placeholder="Username"
                        autoComplete="username"
                        value={state.email}
                        onChange={update} required
                        name="email" />

                    </CInputGroup>
                    <CInputGroup className="mb-4 has-validation">
                      <CInputGroupText>
                        <CIcon icon={cilLockLocked} />
                      </CInputGroupText>
                      <CFormInput
                        type="password"
                        placeholder="Password"
                        autoComplete="current-password"
                        value={state.password}
                        onChange={update}
                        name="password" required
                      />
                    </CInputGroup>
                    <CRow className="justify-content-evenly">
                      <CCol xs={6}>
                        <CLoadingButton color="primary" loading={isLoading} className="px-4" type='submit'>
                          Login
                        </CLoadingButton>
                      </CCol>
                      <CCol xs={6}  >
                        < CButton color="primary" className="px-2"  >
                          Forgot password?
                        </CButton>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
        <CToaster push={toast} placement="top-end" />
      </CContainer>
    </div>
  )
}

export default Login
