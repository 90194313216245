export enum FormSubmit {
    Save, Update, Delete,
}
export enum Privilage {
    Superadmin = 'Superadmin',
    Headmaster = 'Headmaster',
    Admin = 'Admin',
    Editor = 'Editor',
    Teacher = 'Teacher',
    Student = 'Student',
    New = 'New',
    Alumni = 'Alumni'
}

