import { NavLink } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
  CContainer,
  CHeader,
  CHeaderBrand,
  CHeaderDivider,
  CHeaderNav,
  CHeaderToggler,
  CNavLink,
  CNavItem,
  CFormSelect,
} from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import { cilBell, cilEnvelopeOpen, cilList, cilMenu } from '@coreui/icons-pro'

import { AppBreadcrumb } from './index'
import { AppHeaderDropdown } from './header/index'
import { logo } from '../assets/brand/logo'
import { setSidebarShow, sidebarShow } from '../features/config/configSlice'
import { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import Cookies from 'js-cookie'
import i18next from '../app/i18n'

const AppHeader = () => {
  const { t } = useTranslation()

  const languages = [
    {
      code: 'id',
      name: 'Indonesia',
      country_code: 'id',
    },
    {
      code: 'en',
      name: 'English',
      country_code: 'gb',
    },
  ]

  const currentLanguageCode = Cookies.get('i18next') || 'id'
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode)

  const dispatch = useDispatch()
  const issidebarShow = useSelector(sidebarShow)

  const changeLang = (e: ChangeEvent<HTMLSelectElement>) => {
    i18next.changeLanguage(e.target.value);
  }

  return (
    <CHeader position="sticky" className="mb-4">
      <CContainer fluid>
        <CHeaderToggler
          className="ps-1"
          onClick={() => dispatch(setSidebarShow(!issidebarShow))}
        >
          <CIcon icon={cilMenu} size="lg" />
        </CHeaderToggler>
        <CHeaderBrand className="mx-auto d-md-none"  >
          <CIcon icon={logo} height={48} />
        </CHeaderBrand>
        <CHeaderNav className="d-none d-md-flex me-auto">
          <CNavItem>
            <CNavLink to="/dashboard" component={NavLink}>
              {t("dashboard")}
            </CNavLink>
          </CNavItem>
          {/* <CNavItem>
            <CNavLink href="#">Users</CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink href="#">Settings</CNavLink>
          </CNavItem> */}
        </CHeaderNav>
        <CHeaderNav>
          <CFormSelect
            onChange={changeLang}
            options={
              languages.map((a) => { return { label: a.name, value: a.code } })
            }
            value={currentLanguage?.code}
          />
          <CNavItem>

            <CNavLink href="#">
              <CIcon icon={cilBell} size="lg" />
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink href="#">
              <CIcon icon={cilList} size="lg" />
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink href="#">
              <CIcon icon={cilEnvelopeOpen} size="lg" />
            </CNavLink>
          </CNavItem>
        </CHeaderNav>
        <CHeaderNav className="ms-3">
          <AppHeaderDropdown />
        </CHeaderNav>
      </CContainer>
      <CHeaderDivider />
      <CContainer fluid>
        <AppBreadcrumb />
      </CContainer>
    </CHeader>
  )
}

export default AppHeader
