import CIcon from '@coreui/icons-react'
import {
  cilPuzzle, cilStar,
} from '@coreui/icons-pro'
import { CNavGroup, CNavItem, CNavTitle } from '@coreui/react-pro'
import { Privilage } from './app/enums'

const nav = [
  {
    component: CNavTitle,
    name: 'master',
    role: [
    ]
  },
  {
    component: CNavGroup,
    name: 'master',
    icon: <CIcon icon={cilPuzzle} customClassName="nav-icon" />,
    items: [

      {
        component: CNavItem,
        name: 'class',
        to: '/Masters/Class',
        role: [
          Privilage.Superadmin,
          Privilage.Headmaster,
          Privilage.Admin,
          Privilage.Editor,
        ],
      },
      {
        component: CNavItem,
        name: 'departement',
        to: '/Masters/Departement',
        role: [
          Privilage.Superadmin,
          Privilage.Headmaster,
          Privilage.Admin,
          Privilage.Editor,
        ],
      },
      {
        component: CNavItem,
        name: 'educationLevel',
        to: '/Masters/EducationLevel',
        role: [
          Privilage.Superadmin,
          Privilage.Headmaster,
          Privilage.Admin,
          Privilage.Editor,
        ],
      },
      {
        component: CNavItem,
        name: 'employee',
        to: '/Masters/Employee',
        role: [
          Privilage.Superadmin,
          Privilage.Headmaster,
          Privilage.Admin,
          Privilage.Editor,
        ],
      },
      {
        component: CNavItem,
        name: 'group',
        to: '/Masters/Group',
        role: [
          Privilage.Superadmin,
          Privilage.Headmaster,
          Privilage.Admin,
          Privilage.Editor,
        ],
      },
      {
        component: CNavItem,
        name: 'major',
        to: '/Masters/Major',
        role: [
          Privilage.Superadmin,
          Privilage.Headmaster,
          Privilage.Admin,
          Privilage.Editor,
        ],
      },
      {
        component: CNavItem,
        name: 'rank',
        to: '/Masters/Rank',
        role: [
          Privilage.Superadmin,
          Privilage.Headmaster,
          Privilage.Admin,
          Privilage.Editor,
        ],
      },
      {
        component: CNavItem,
        name: 'room',
        to: '/Masters/Rooms',
        role: [
          Privilage.Superadmin,
          Privilage.Headmaster,
          Privilage.Admin,
          Privilage.Editor,
        ],
      },
      {
        component: CNavItem,
        name: 'school',
        to: '/Masters/School',
        role: [
          Privilage.Superadmin,
          Privilage.Headmaster,
          Privilage.Admin,
          Privilage.Editor,
        ],
      },
      {
        component: CNavItem,
        name: 'semester',
        to: '/Masters/Semester',
        role: [
          Privilage.Superadmin,
          Privilage.Headmaster,
          Privilage.Admin,
          Privilage.Editor,
        ],
      },
      {
        component: CNavItem,
        name: 'subject',
        to: '/Masters/Subject',
        role: [
          Privilage.Superadmin,
          Privilage.Headmaster,
          Privilage.Admin,
          Privilage.Editor,
        ],
      },
      {
        component: CNavItem,
        name: 'task',
        to: '/Masters/Task',
        role: [
          Privilage.Superadmin,
          Privilage.Headmaster,
          Privilage.Admin,
          Privilage.Editor,
        ],
      },

    ]
  },
  {
    component: CNavItem,
    name: 'exam',
    to: '/Exams',
    icon: <CIcon icon={cilPuzzle} customClassName="nav-icon" />,
    role: [
      Privilage.Superadmin,
      Privilage.Headmaster,
      Privilage.Admin,
      Privilage.Editor,
    ],
  },
  {
    component: CNavItem,
    name: 'homeRoom',
    to: '/Homeroom',
    icon: <CIcon icon={cilPuzzle} customClassName="nav-icon" />,
    role: [
      Privilage.Superadmin,
      Privilage.Headmaster,
      Privilage.Admin,
      Privilage.Editor,
    ],
  },

  {
    component: CNavItem,
    name: 'student',
    to: '/Student',
    icon: <CIcon icon={cilPuzzle} customClassName="nav-icon" />,
    role: [
      Privilage.Superadmin,
      Privilage.Headmaster,
      Privilage.Admin,
      Privilage.Editor,
    ],
  },
  {
    component: CNavGroup,
    name: 'teacher',
    icon: <CIcon icon={cilPuzzle} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'Teacher',
        to: 'Teacher',
        role: [
          Privilage.Superadmin,
          Privilage.Headmaster,
          Privilage.Admin,
          Privilage.Editor,
        ],
      },
      {
        component: CNavItem,
        name: 'task',
        to: '/TeacherTask',
        role: [
          Privilage.Superadmin,
          Privilage.Headmaster,
          Privilage.Admin,
          Privilage.Editor,
        ],
      },
    ]
  },
  {
    component: CNavItem,
    name: 'subjectDepartement',
    to: '/SubjectDepartement',
    icon: <CIcon icon={cilPuzzle} customClassName="nav-icon" />,
    role: [
      Privilage.Superadmin,
      Privilage.Headmaster,
      Privilage.Admin,
      Privilage.Editor,
    ],
  },

  {
    component: CNavGroup,
    name: 'Icons',
    icon: <CIcon icon={cilStar} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'CoreUI Duotones',
        to: '/icons/duotones',
        badge: {
          color: 'success',
          text: 'NEW',
        },
      },
      {
        component: CNavItem,
        name: 'CoreUI Flags',
        to: '/icons/flags',
      },
      {
        component: CNavItem,
        name: 'CoreUI Brands',
        to: '/icons/brands',
      },
      {
        component: CNavItem,
        name: 'CoreUI linears',
        to: '/icons/linears',
      },
      {
        component: CNavItem,
        name: 'CoreUI solids',
        to: '/icons/solids',
      },
    ],
  },
]

export default nav
