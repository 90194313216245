import React from 'react'
import { Privilage } from './app/enums'

const Class = React.lazy(() => import('./views/pages/classes/Classess'))
const Departement = React.lazy(() => import('./views/pages/departements/Departements'))
const Teachers = React.lazy(() => import('./views/pages/teachers/Teachers'))
const Dashboard = React.lazy(() => import('./views/dashboard/Dashboard'))
const EducationLevel = React.lazy(() => import('./views/pages/educationLevels/EducationLevels'))
const Employee = React.lazy(() => import('./views/pages/employees/Employees'))
const Exams = React.lazy(() => import('./views/pages/exam/Exams'))
const Group = React.lazy(() => import('./views/pages/group/Groups'))
const Homeroom = React.lazy(() => import('./views/pages/homeroom/Homerooms'))
const Major = React.lazy(() => import('./views/pages/Major/Majors'))
const Rank = React.lazy(() => import('./views/pages/Rank/Ranks'))
const Rooms = React.lazy(() => import('./views/pages/Rooms/Rooms'))
const Subject = React.lazy(() => import('./views/pages/subject/Subjects'))
const Student = React.lazy(() => import('./views/pages/student/Students'))
const TeacherTask = React.lazy(() => import('./views/pages/TeacherTask/TeacherTasks'))
const Task = React.lazy(() => import('./views/pages/Task/Tasks'))
const School = React.lazy(() => import('./views/pages/School/Schools'))
const Semester = React.lazy(() => import('./views/pages/semester/Semesters'))
const SubjectDepartement = React.lazy(() => import('./views/pages/SubjectDepartement/SubjectDepartements'))

const Flags = React.lazy(() => import('./views/icons/flags/Flags'))
const Brands = React.lazy(() => import('./views/icons/brands/Brands'))
const Duotones = React.lazy(() => import('./views/icons/duotone/Duotones'))
const Linears = React.lazy(() => import('./views/icons/linears/Linears'))
const Solids = React.lazy(() => import('./views/icons/solids/Solids'))


const routes = [
  { path: '/', exact: true, name: 'Home' },
  {
    path: '/Masters/Class', name: 'class', element: Class, role: [
      Privilage.Superadmin,
      Privilage.Headmaster,
      Privilage.Admin,
    ]
  },
  {
    path: '/Masters/Departement', name: 'departement', element: Departement, role: [
      Privilage.Superadmin,
      Privilage.Headmaster,
      Privilage.Admin,
    ]
  },
  {
    path: '/dashboard', name: 'dashboard', element: Dashboard, role: [
      // Privilage.Superadmin,
      // Privilage.Headmaster,
      // Privilage.Admin,
    ]
  },

  {
    path: '/Masters/EducationLevel', name: 'educationLevel', element: EducationLevel, role: [
      Privilage.Superadmin,
      Privilage.Headmaster,
      Privilage.Admin,
    ]
  },
  {
    path: '/Teacher', name: 'teacher', element: Teachers, role: [
      Privilage.Superadmin,
      Privilage.Headmaster,
      Privilage.Admin,
    ]
  },
  
  {
    path: '/SubjectDepartement', name: 'subjectDepartement', element: SubjectDepartement, role: [
      Privilage.Superadmin,
      Privilage.Headmaster,
      Privilage.Admin,
    ]
  },
 
  {
    path: '/Masters/Employee', name: 'employee', element: Employee, role: [
      Privilage.Superadmin,
      Privilage.Headmaster,
      Privilage.Admin,
    ]
  },
  {
    path: '/Masters/Exams', name: 'exam', element: Exams, role: [
      Privilage.Superadmin,
      Privilage.Headmaster,
      Privilage.Admin,
    ]
  },
  {
    path: '/Masters/Group', name: 'group', element: Group, role: [
      Privilage.Superadmin,
      Privilage.Headmaster,
      Privilage.Admin,
    ]
  },
  {
    path: '/Homeroom', name: 'homeRoom', element: Homeroom, role: [
      Privilage.Superadmin,
      Privilage.Headmaster,
      Privilage.Admin,
    ]
  },
  {
    path: '/Masters/Rooms', name: 'room', element: Rooms, role: [
      Privilage.Superadmin,
      Privilage.Headmaster,
      Privilage.Admin,
    ]
  },
  {
    path: '/Masters/School', name: 'school', element: School, role: [
      Privilage.Superadmin,
      Privilage.Headmaster,
      Privilage.Admin,
    ]
  },
  {
    path: '/Masters/Semester', name: 'semester', element: Semester, role: [
      Privilage.Superadmin,
      Privilage.Headmaster,
      Privilage.Admin,
    ]
  },
  {
    path: '/Masters/Major', name: 'major', element: Major, role: [
      Privilage.Superadmin,
      Privilage.Headmaster,
      Privilage.Admin,
    ]
  },
  {
    path: '/Masters/Rank', name: 'rank', element: Rank, role: [
      Privilage.Superadmin,
      Privilage.Headmaster,
      Privilage.Admin,
    ]
  },
  {
    path: '/Masters/Subject', name: 'subject', element: Subject, role: [
      Privilage.Superadmin,
      Privilage.Headmaster,
      Privilage.Admin,
    ]
  },
  {
    path: '/Student', name: 'student', element: Student, role: [
      Privilage.Superadmin,
      Privilage.Headmaster,
      Privilage.Admin,
    ]
  },
  {
    path: '/Masters/Task', name: 'task', element: Task, role: [
      Privilage.Superadmin,
      Privilage.Headmaster,
      Privilage.Admin,
    ]
  },
  {
    path: '/TeacherTask', name: 'teacherTask', element: TeacherTask, role: [
      Privilage.Superadmin,
      Privilage.Headmaster,
      Privilage.Admin,
    ]
  },

  { path: '/icons/duotones', name: 'Duotones', element: Duotones ,role: []},
  { path: '/icons/flags', name: 'Flags', element: Flags ,role: []},
  { path: '/icons/brands', name: 'Brands', element: Brands ,role: []},
  { path: '/icons/linears', name: 'Linears', element: Linears ,role: []},
  { path: '/icons/solids', name: 'Solids', element: Solids ,role: []},

]

export default routes
