import { configureStore, ThunkAction, Action, getDefaultMiddleware } from '@reduxjs/toolkit';
import configReducer from '../features/config/configSlice';
import authSlice from '../features/auth/authSlice';
import baseQuery from './api/apiSlice';

export const store = configureStore({
  reducer: {
    config: configReducer,
    auth: authSlice,
    [baseQuery.reducerPath]: baseQuery.reducer, 
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      baseQuery.middleware, 
    ),
  devTools: process.env.NODE_ENV !== 'production',
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
