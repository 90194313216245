import { createSlice } from "@reduxjs/toolkit"
import { RootState } from "../../app/store"

const initialState = {
    token: localStorage.getItem('token') ?? ''
}
const authSlice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        setCredentials: (state, { payload, type }) => {
            const { token } = payload
            state.token = token
            localStorage.setItem('token', token);
        },
        logOut: (state, action) => {
            state.token = ''
            localStorage.removeItem("token")
        }
    },
})

export const { setCredentials, logOut } = authSlice.actions

export default authSlice.reducer

export const selectCurrentToken = (state: RootState) => state.auth.token