import { Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { CContainer, CSpinner } from '@coreui/react-pro'

// routes config
import routes from '../routes'
import RequireAuth from '../features/auth/RequireAuth'
import RoleUser from '../features/auth/RoleUser'

const AppContent = () => {
  return (
    <CContainer fluid>
      <Suspense fallback={<CContainer sm style={{ width: "10px", height: "100px" }} >
        <CSpinner color="primary" />
      </CContainer>}>
        <Routes>
          {routes.map((route, idx) => {
            return (
              route.element && (
                <Route key={idx} element={<RequireAuth />}>
                  <Route key={idx} element={<RoleUser allowedRoles={route.role!} />}>
                    <Route
                      key={idx}
                      path={route.path}
                      element={<route.element />}
                    />
                  </Route>
                </Route>
              )
            )
          })}
          <Route path="/" element={<Navigate to="dashboard" replace />} />
        </Routes>
      </Suspense>
    </CContainer>
  )
}

export default AppContent 
