import { NavLink, useLocation } from 'react-router-dom'
import routes from '../routes'
import { CBreadcrumb, CBreadcrumbItem } from '@coreui/react-pro'
import { useTranslation } from 'react-i18next';

const AppBreadcrumb = () => {
  const { t } = useTranslation();

  const currentLocation = useLocation().pathname

  const getRouteName = (pathname: any, routes: any) => {
    const currentRoute = routes.find((route: any) => route.path === pathname)
    return currentRoute ? currentRoute.name : false
  }

  const getBreadcrumbs = (location: any) => {
    const breadcrumbs: any[] = []
    location.split('/').reduce((prev: any, curr: any, index: any, array: any) => {
      const currentPathname = `${prev}/${curr}`
      const routeName = getRouteName(currentPathname, routes)
      routeName &&
        breadcrumbs.push({
          pathname: currentPathname,
          name: routeName,
          active: index + 1 === array.length ? true : false,
        })
      return currentPathname
    })
    return breadcrumbs
  }

  const breadcrumbs = getBreadcrumbs(currentLocation)

  return (
    <CBreadcrumb className="m-0 ms-2">
      <CBreadcrumbItem
      >
        <NavLink to='/' >{t('home')}</NavLink>
      </CBreadcrumbItem>
      {
        breadcrumbs.map(
          (breadcrumb, index) => {
            return (
              <CBreadcrumbItem
                {...(breadcrumb.active ? { active: false } : {})}
                key={index}
              >
                {(breadcrumb.active ? t(breadcrumb.name) : <NavLink to={breadcrumb.pathname} >{t(breadcrumb.name)}</NavLink>)}
              </CBreadcrumbItem>
            )
          }
        )
      }
    </CBreadcrumb>
  )
}

export default AppBreadcrumb
