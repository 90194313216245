import {  createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

export interface configState {
  sidebarUnfoldable: boolean;
  sidebarShow: boolean;
}

const initialState: configState = {
  sidebarUnfoldable:true,
  sidebarShow:true
}; 

export const counterSlice = createSlice({
  name: 'counter',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setSidebarUnfoldable: (state,action) => {
      state.sidebarUnfoldable = action.payload;
    },
    setSidebarShow: (state,action) => {
      state.sidebarShow = action.payload;
    },
     
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {}
    
});

export const { setSidebarUnfoldable,setSidebarShow } = counterSlice.actions;
export const sidebarUnfoldable = (state: RootState) => state.config.sidebarUnfoldable;
export const sidebarShow = (state: RootState) => state.config.sidebarShow;

export default counterSlice.reducer;
