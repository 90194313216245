import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { CSidebar, CSidebarBrand, CSidebarNav, CSidebarToggler } from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'

import { AppSidebarNav } from './AppSidebarNav'

// import { logoNegative } from '../assets/brand/logo-negative'
// import { sygnet } from '../assets/brand/sygnet'

import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'

// sidebar nav config
import navigation from '../nav'
import { setSidebarShow, sidebarShow, sidebarUnfoldable, setSidebarUnfoldable } from '../features/config/configSlice'
import { cibMediumM } from '@coreui/icons-pro'

const AppSidebar = () => {
  const dispatch = useDispatch()
  const unfoldable = useSelector(sidebarUnfoldable)
  const issidebarShow = useSelector(sidebarShow)

  return (
    <CSidebar
      position="fixed"
      unfoldable={unfoldable}
      visible={issidebarShow}
      onVisibleChange={(visible:boolean) => {
        dispatch(setSidebarShow(visible))
      }}
    >
      <CSidebarBrand className="d-none d-md-flex" >
        {/* full */}
        <CIcon className="sidebar-brand-full" icon={cibMediumM} height={35} /> 
        {/* small */}
        <CIcon className="sidebar-brand-narrow" icon={cibMediumM} height={35} />
      </CSidebarBrand>
      <CSidebarNav>
        <SimpleBar>
          <AppSidebarNav items={navigation} />
        </SimpleBar>
      </CSidebarNav>
      <CSidebarToggler
        className="d-none d-lg-flex"
        onClick={() => dispatch(setSidebarUnfoldable(!unfoldable))}
      />
    </CSidebar>
  )
}

export default AppSidebar
