import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { LoginTeacher } from '../../views/pages/teachers/teacher';
import { selectCurrentToken } from './authSlice';
import jwt_decode from "jwt-decode";
import { LoginStudent } from '../../app/models/student';

export interface Roles {
    allowedRoles: string[]
}

const RoleUser = (allowedRoles: Roles) => {
    const token = useSelector(selectCurrentToken)
    const decoded = jwt_decode<LoginTeacher | LoginStudent>(token);
    const location = useLocation()
    return (
        allowedRoles.allowedRoles.length === 0 || allowedRoles.allowedRoles.find(role => decoded?.user.privilegeName === role)
            ? <Outlet />
            : token
                ? <Navigate to="404" state={{ from: location }} replace />
                : <Navigate to="login" state={{ from: location }} replace />
    );

};

export default RoleUser;