import {
  CCol,
  CContainer,
  CRow,
} from '@coreui/react-pro'
import { useNavigate } from 'react-router-dom'

const Unauthorized = () => {
  const navigate = useNavigate();

  const goBack = () => navigate(-1);
  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={12}>
            <div className="clearfix">
              <h1 className="float-start display-3 me-4">Unauthorized</h1>
            </div>
          </CCol>
          <CCol md={12}>
          <div className="flexGrow">
                <button onClick={goBack}>Go Back</button>
            </div>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default Unauthorized
