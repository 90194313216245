import baseQuery from "../../app/api/apiSlice"

const apiWithTag = baseQuery.enhanceEndpoints({ addTagTypes: ['Login'] })
export const authApiSlice = apiWithTag.injectEndpoints({
    endpoints: builder => ({
        login: builder.mutation({
            query: credentials => ({
                url: '/Teacher/login',
                method: 'POST',
                body: { ...credentials }
            }),
            invalidatesTags: [{ type: 'Login' }],
        }),
        loginStudent: builder.mutation({
            query: credentials => ({
                url: '/Student/login',
                method: 'POST',
                body: { ...credentials }
            }),
            invalidatesTags: [{ type: 'Login' }],
        }),
    })
})

export const {
    useLoginMutation,
    useLoginStudentMutation,
} = authApiSlice