import { Suspense } from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import RequireAuth from "./features/auth/RequireAuth";


import DefaultLayout from "./layout/DefaultLayout";
import Login from "./views/pages/login/Login";
import Page404 from "./views/pages/page404/Page404";
import Page500 from "./views/pages/page500/Page500";
import Register from "./views/pages/register/Register";
import Unauthorized from "./views/pages/unauthorized/Unauthorized";

function App() {
  const loading = (
    <div className="pt-3 text-center">
      <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
  )

  return (
    <>
      <HashRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route path="login" element={<Login />} />
            <Route path="register" element={<Register />} />
            <Route path="404" element={<Page404 />} />
            <Route path="500" element={<Page500 />} />
            <Route path="unauthorized" element={<Unauthorized />} />
            <Route element={<RequireAuth />}>
              <Route path="*" element={<DefaultLayout />} />
            </Route>
          </Routes>
        </Suspense>
      </HashRouter>
    </>

  );
}

export default App;
