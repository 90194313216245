import { CToast, CToastBody, CToastHeader } from "@coreui/react-pro";

export interface Prop {
  title?: string,
  value?:string,
  type : "error"
}

export const sendToast = (prop: Prop) => (
  <CToast>
    <CToastHeader closeButton>
      <strong className={ prop.type === "error" ? "me-auto text-danger" : "me-auto"}>{prop.title}</strong>
    </CToastHeader>
    <CToastBody>{prop.value}</CToastBody>
  </CToast>
)